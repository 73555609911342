import React, { useState, useEffect } from 'react';
import { Input } from '../../components/inputs/input';
import Layout from '../../components/layout';

const urls = [
  'https://datastudio.google.com/embed/reporting/83c90e07-72e4-465a-a05b-06019151a481/page/HgrpB',
  'https://datastudio.google.com/embed/reporting/2c232d00-5641-4d52-a770-b2ddcafac592/page/SvksB',
  'https://datastudio.google.com/embed/reporting/83c90e07-72e4-465a-a05b-06019151a481/page/uOKqB',
  'https://datastudio.google.com/embed/reporting/2c232d00-5641-4d52-a770-b2ddcafac592/page/ANPhB',
  'https://datastudio.google.com/embed/reporting/2c232d00-5641-4d52-a770-b2ddcafac592/page/YiWhB'
];

const PASSWORD = 'hourly';

export const LeaderBoard = () => {
  const [indexURL, setIndexURL] = useState<number>(0);
  const [currentURL, setCurrentURL] = useState<string>(urls[0]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState();
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);
  const [password, setPassword] = useState<string>('');
  const props = { currentTab, setCurrentTab, licensedInOpened, setLicensedInOpened, getAQuoteOpened, setGetAQuoteOpened };

  setTimeout(() => {
    if (indexURL === urls.length - 1) {
      setIndexURL(0);
    } else {
      setIndexURL(indexURL + 1);
    }
  }, 100000);

  useEffect(() => {
    setCurrentURL(urls[indexURL]);
  }, [indexURL]);

  const updatePassword = event => {
    setPassword(event.target.value);
  };

  const submitForm = event => {
    // const request = axios({
    //   method: 'post',
    //   url: VERIFY_PASSWORD_URL,
    //   data: JSON.stringify({
    //     post_password: 'value1',
    //     Submit: 'Enter'
    //   }),
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    //   }
    // })
    //   .then(result => console.log('Result', result.data))
    //   .catch(error => alert(error));
    event.preventDefault();
    if (password === PASSWORD) {
      setIsValid(true);
    } else {
      alert('Invalid password!');
    }
  };

  return (
    <Layout {...props} hasGetAQuoteButton={false}>
      {!isValid ? (
        <div className='leaderboard'>
          <label className='leaderboard__label'>
            <b>This content is password protected. To view it please enter your password below:</b>
          </label>
          <form onSubmit={submitForm}>
            <div className='leaderboard__form'>
              <div className='leaderboard__form__input'>
                <label>Password:</label>
                <Input onChange={updatePassword} name='post_password' id='pwbox-1554' type='password' size='20' className='leaderboard__form__input_password' />
              </div>
              <div className='leaderboard__form__verify_password'>
                <button className='leaderboard__form__verify_password_button' type='submit'>
                  Enter
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '20vh' }}>
          <p>
            <section className='content-block content-block--small-title content-block--center'>
              <iframe id='rotator' src={currentURL} width='800' height='500' allowFullScreen={true} frameBorder='0' />
            </section>
          </p>
        </div>
      )}
    </Layout>
  );
};

export default LeaderBoard;
